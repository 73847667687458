import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { AvatarAIcon } from "@partner-tech/pax-icons"
import { Menu, MenuItem } from "@tb/desy"
import cn from "classnames"
import { config } from "@/config"
import { usePlatform } from "@/contexts/PlatformContext"

const iconSize = 24

interface ProfileMenuProps {
    title?: string
}

export const ProfileMenu = ({ title }: ProfileMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const navigate = useNavigate()
    const { logout } = useAuth0()
    const { isWlz } = usePlatform()

    const handleClick = (event: React.MouseEvent<Element>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        logout({ logoutParams: { returnTo: config.auth.AUTH0_LOGOUT_URL } })
            .then(() => console.log("Logged out successfully!"))
            .catch((error) => {
                console.error("Failed to logout", error)
            })
        handleClose()
    }

    const handleCredentials = () => {
        navigate("/admin")
        handleClose()
    }

    return (
        <div className="mt-auto">
            <div
                onClick={handleClick}
                className={cn(`flex w-full cursor-pointer flex-col items-center justify-between p-2 text-background-default`, {
                    "text-black": isWlz,
                })}
                data-testid={"profile-menu"}
            >
                <div className={"rounded bg-background-default p-2 text-black"}>
                    <AvatarAIcon size={iconSize} />
                </div>
                {title && <div className={"mt-2 text-center text-sm"}>{title}</div>}
            </div>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={handleCredentials} data-testid={"api-client-credentials"}>
                    API Client Credentials
                </MenuItem>
                <MenuItem onClick={handleLogout} data-testid={"logout"}>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    )
}
